import React from "react";
import SignUp from "../components/SignUp";
import ScrollToTop from "../components/ScrollToTop";

function SignupPage() {
    return (
        <>
            <ScrollToTop />
            <SignUp />
        </>
    );
}

export default SignupPage;
