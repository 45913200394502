import React from "react";
import SignIn from "../components/SignIn";
import ScrollToTop from "../components/ScrollToTop";

function SigninPage() {
    return (
        <>
            <ScrollToTop />
            <SignIn />
        </>
    );
}

export default SigninPage;
